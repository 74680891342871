import { toggleSnack } from '@/plugins/vuex/mutationTypes';

export const snackSuccess = (commit, message) => commit(toggleSnack, {
  color: 'success',
  message,
}, { root: true });
export const snackError = (commit, message) => commit(toggleSnack, {
  color: 'error',
  message,
}, { root: true });
