import { snackError } from '@/helpers/store';
import store from '@/plugins/vuex';

export default function errorHandler(err) {
  const errResponse = err.response;

  if (errResponse) {
    console.warn(errResponse);

    // ToDo пока на бэке нет глобальных кодов
    snackError(store.commit, 'Произошла ошибка');
    return;
  }
  // axios message 'Error: Request failed with status code xxx'
  console.warn(err);
}
