<template>
  <div>
    <TTView>
      <VRow justify="center">
        <VCol
          lg="6"
          md="6"
          sm="12"
          xs="12"
        >
          <VImg
            :src="require('@/assets/img/end_img.svg')"
            width="100%"
          />
        </VCol>
      </VRow>

      <VRow>
        <VCol class="text-center">
          <h1 class="font-weight-medium">
            {{ $t('thanksForParticipate') }}
          </h1>
          <p class="tt-black--text text--lighten-1 body-2 text-pre-line">
            {{ $t('noReminders') }}
          </p>
        </VCol>
      </VRow>
    </TTView>
  </div>
</template>

<script>
import TTView from '@/components/TTView.vue';
import { doNotDisturb } from '@/services/api';
import { toggleLoading } from '@/plugins/vuex/mutationTypes';
import errorHandler from '@/helpers/errorHandler';
import { mapMutations } from 'vuex';

export default {
  name: 'UserSurveyDoNotDisturb',
  components: { TTView },

  async created() {
    this[toggleLoading](true);
    try {
      await doNotDisturb(this.$route.params.slug);
    } catch (err) {
      errorHandler(err);
    } finally {
      this[toggleLoading](false);
    }
  },

  methods: {
    ...mapMutations([toggleLoading]),
  },
};
</script>
