import {
  HTTP_GET, HTTP_POST, HTTP_DELETE, HTTP_PUT,
} from '@/services/api/constants';
import transport from '@/services/api/resources/axios.transport';

// TODO refactor уменьшить простыню, разделить на неск файлов-сервисов e.g.: surveys, teams, user_surveys, start_surveys

export const surveyInstructionGet = (slug, options) => transport({
  method: HTTP_GET,
  url: `/surveys/${slug}/instruction`,
  ...options,
});

export const surveyStatusGet = (slug, options) => transport({
  method: HTTP_GET,
  url: `/surveys/${slug}/status`,
  ...options,
});

// Operations about user_surveys
export const surveySessionWelcomeGet = (slug, options) => transport({
  method: HTTP_GET,
  url: `/user_surveys/${slug}/welcome`,
  ...options,
});

export const surveyMove = (slug, data) => transport({
  method: HTTP_POST,
  url: `/user_surveys/${slug}/move`,
  data,
});

export const surveyPageNumberGet = (slug, pageNumber, options) => transport({
  method: HTTP_GET,
  url: `/user_surveys/${slug}/pages/${pageNumber}`,
  ...options,
});

export const answerCreate = (slug, data, options) => transport({
  method: HTTP_POST,
  url: `/user_surveys/${slug}/answers`,
  data,
  ...options,
});

export const answerUpdate = (slug, data, options) => transport({
  method: HTTP_POST,
  url: `/user_surveys/${slug}/answers`,
  data,
  ...options,
});

export const answerDelete = (slug, data, options) => transport({
  method: HTTP_DELETE,
  url: `/user_surveys/${slug}/answers`,
  data,
  ...options,
});

export const answerWithIdDelete = (answerId, data, options) => transport({
  url: `/user_answers/${answerId}`,
  method: HTTP_DELETE,
  data,
  ...options,
});

export const surveyLanguagesGet = (slug, options) => transport({
  method: HTTP_GET,
  url: `/user_surveys/${slug}/available_languages`,
  ...options,
});

export const surveyLanguageUpdate = (slug, data = {}, options) => transport({
  method: HTTP_PUT,
  url: `/user_surveys/${slug}/current_language`,
  data,
  ...options,
});

export const surveyCurrentContextGet = (options) => transport({
  method: HTTP_GET,
  url: '/current_context',
  ...options,
});

// Operations about teams
export const teamGetByName = (data = {}, options) => transport({
  url: '/teams',
  method: HTTP_GET,
  data,
  ...options,
});

// Operations about start_surveys
export const surveyByUserSlugWelcomeGet = (surveySlug, options) => transport({
  method: HTTP_GET,
  url: `/start_survey/${surveySlug}/welcome`,
  ...options,
});

export const surveyByUserSlugCreate = (surveySlug, options) => transport({
  method: HTTP_POST,
  url: `/start_survey/${surveySlug}`,
  ...options,
});

export const surveyByUserSlugUpdate = (userSurveySlug, options) => transport({
  method: HTTP_PUT,
  url: `/user_surveys/${userSurveySlug}`,
  ...options,
});

export const surveyByUserSlugMetaFilterValuesGet = (userSurveySlug, options) => transport({
  url: `/user_surveys/${userSurveySlug}/meta-filter-questions`,
  method: HTTP_GET,
  ...options,
});

export const questionCommentPost = (options) => transport({
  url: '/comments',
  method: HTTP_POST,
  ...options,
});

export const surveyFinalPageGet = (userSurveySlug, options) => transport({
  method: HTTP_GET,
  url: `/surveys/${userSurveySlug}/final_page`,
  ...options,
});

export const surveyOrgstructurePageGet = (userSurveySlug, options) => transport({
  method: HTTP_GET,
  url: `/user_surveys/${userSurveySlug}/orgstructure_page`,
  ...options,
});

export const doNotDisturb = (userSurveySlug, options) => transport({
  method: HTTP_GET,
  url: `/user_surveys/${userSurveySlug}/do_not_disturb`,
  ...options,
});
